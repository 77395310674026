import { Inject, Injectable } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, InteractionType, Logger, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { AccountInfo, LogLevel } from '@azure/msal-common';
import { CryptoUtils } from 'msal';
import { Observable, ReplaySubject, Subject, Subscription, of } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ApplicationInsightsService } from 'src/app/core/services/application-insights.service';
import { EventType } from "@azure/msal-browser"
import { environment } from 'src/environments/environment';
import { IDTokenClaims, UserRoles } from '../types/auth.interface';
import { UsersService } from 'src/app/users/services/users.service';
import { User } from '../models/user';
import { CachedDataServiceV2 } from 'src/app/core/classes/cached.data.service.v2';
import { HttpClient } from '@angular/common/http';
import { User2Service } from 'src/app/users/services/user2.service';
import { CompaniesV2Service } from 'src/app/companies/services/companies.v2.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends CachedDataServiceV2<User> {

  // msal and life cycles
  public isIframe = false;
  private readonly _destroying$ = new Subject<void>();

  private _subscriptions = new Map<string, Subscription>();

  // account
  private readonly $account = new ReplaySubject<AccountInfo | undefined>(1);
  public readonly account$ = this.$account.pipe(shareReplay(1));
  private readonly $userName = new ReplaySubject<string>(1);
  public readonly userName$ = this.$userName.pipe(distinctUntilChanged(), shareReplay(1));
  private readonly $name = new ReplaySubject<string | undefined>(1);
  public readonly name$ = this.$name.pipe(distinctUntilChanged(), shareReplay(1));

  // user icon
  public readonly fallbackAccountIconUrl = this.getAccountIconUrl('User');
  public readonly accountIconUrl$ = this.$userName.pipe(
    map(name => this.getAccountIconUrl(name))
  );

  // user in database
  private $currentUser = new ReplaySubject<User>(1);
  public currentUser$ = this.$currentUser.asObservable();
  public setCurrentUser(user: User) {
    this.$currentUser.next(user);
  }

  // user is from a partner company for some frontend button validation
  userIsPartner$ = this.currentUser$.pipe(
    map(user => user.companyType === 'partner')
  )

  // user type
  private $isClient = new ReplaySubject<boolean>(1);
  public isClient$ = this.$isClient.asObservable();
  public setIsClient(isClient: boolean) {
    this.$isClient.next(isClient);
  }

  // user country
  public userCountry$ = this.currentUser$.pipe(
    switchMap(user => {
      if (user?.companyId && user.role !== 'SuperAdmin') {
        return this.companiesService2.get({ id: user.companyId }).pipe(
          map(company => company.countryCode),
          distinctUntilChanged()
        )
      }
      return of(undefined);
    }),
    distinctUntilChanged()
  );

  // login status and state
  public readonly loggedIn$ = this.account$.pipe(map(account => !!account), distinctUntilChanged(), shareReplay(1));
  public readonly loggedOut$ = this.account$.pipe(map(account => !account), distinctUntilChanged(), shareReplay(1));
  public readonly loginStatus$ = this.msalBroadcastService.inProgress$.pipe(distinctUntilChanged(), shareReplay(1));

  // role info
  private readonly claims$ = this.account$.pipe(map(account => account?.idTokenClaims as unknown as IDTokenClaims));
  private readonly roles$ = this.claims$.pipe(map(claims => claims?.roles || []));
  public readonly isAdmin$ = this.roles$.pipe(map(roles => roles.includes(UserRoles.ADMIN)))
  public readonly role$: Observable<string> = this.isAdmin$.pipe(map(isAdmin => isAdmin ? UserRoles.ADMIN : UserRoles.USER));

  constructor(
    protected override http: HttpClient,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private appInsightService: ApplicationInsightsService,
    private appUserService: UsersService,
    private usersService2: User2Service,
    private companiesService2: CompaniesV2Service,
  ) {
    super(
      http,
      'users'
    );
    this.checkAccount();

    // Set up request for db user when we have a logged in account.
    this.account$.pipe(
      switchMap(account => {
        if (account?.username) {
          return this.getCurrentUser();
        }
        return of(undefined);
      })
    ).subscribe(user => this.$currentUser.next(user as User));

    // Set up listener for login success event.
    this.msalBroadcastService.msalSubject$.subscribe(subject => {
      if (subject.eventType === EventType.LOGIN_SUCCESS) {
        this.checkAccount()
      }
    })

    this.isIframe = window !== window.parent && !window.opener;
    if (environment.MSAL_LOGGING) {
      this.authService.setLogger(new Logger({
        loggerCallback: (level: LogLevel, message: string) => console.log(message),
        correlationId: CryptoUtils.createNewGuid(),
        piiLoggingEnabled: false,
        logLevel: 3
      }));
    }

    // keep track of token refreshes, etc
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this._subscriptions.set(
          'activeUser',
          this.usersService2.active$.subscribe(user => {
            if (user) {
              user.lastActivity = new Date()
              this.usersService2.patch(user).pipe(take(1)).subscribe()
            }
          })
        )
        this.checkAccount();
      });
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest)
      } else {
        this.authService.loginRedirect()
      }
    }
  }

  logout() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
          this.authService.logoutPopup({
            postLogoutRedirectUri: "/",
            mainWindowRedirectUri: "/"
          });
          this.cleanUserData();

          return;
        }

        const numAccounts = this.authService.instance.getAllAccounts().length;
        if (numAccounts === 1) {
          const account = this.authService.instance.getActiveAccount();

          this.authService.logoutRedirect({
            postLogoutRedirectUri: "/",
            account,
          });
          this.cleanUserData();

          return;
        }

        this.authService.logoutRedirect({
          postLogoutRedirectUri: "/",
        });
      });
  }

  private cleanUserData() {
    this.appUserService.updateUsers([])
    this.appInsightService.clearUserId();

    localStorage.clear();
    sessionStorage.clear();
  }

  checkAccount() {
    let activeAccount = this.authService.instance.getActiveAccount()
    const numAccounts = this.authService.instance.getAllAccounts().length;
    if (!activeAccount && numAccounts > 0) {
      const accounts = this.authService.instance.getAllAccounts()
      activeAccount = accounts[0];
      this.authService.instance.setActiveAccount(activeAccount)
      if (numAccounts > 1) {
        console.warn('User has more than one account.', accounts);
      }
    }

    if (activeAccount) {
      this.$account.next(activeAccount);
      this.appInsightService.setUserId(activeAccount.homeAccountId);
      this.$userName.next(activeAccount.name || activeAccount.username);
      this.$name.next(activeAccount.name);
      return activeAccount;
    } else {
      this.$account.next(undefined);
    }
    return null;
  }

  getAccountIconUrl(name: string, size = 48): string {
    const safeName = encodeURIComponent(name.replace(/[a-zåäö ]/gu, '') || '');
    return `https://eu.ui-avatars.com/api/?name=${safeName}&rounded=true&bold=true&background=eeeeee&color=333333&size=${size}`;
  }

  getAccountInfo(): AccountInfo | null {
    return this.authService.instance.getActiveAccount()
  }

  getCurrentUser(): Observable<User> {
    return this.requestGet<User>({
      resource: 'company',
      endpoint: `user/userinfo`,
      id: 'me',
      apiVersion: 2,
    });
  }

  patchCurrentUser(user: User): Observable<User> {
    return this.appUserService.save(user)
      .pipe(tap(res => this.$currentUser.next(res)));
  }

}
