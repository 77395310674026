import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { Company } from '@ramboll/rsedt-shared';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject, Subject, Subscription, combineLatest, debounceTime, distinctUntilChanged, expand, map, shareReplay, startWith, take, tap, toArray } from 'rxjs';
import { CachedDataServiceV2 } from 'src/app/core/classes/cached.data.service.v2';
import { DataServiceRequestOptions, Params } from 'src/app/core/classes/data.service';
import { ComapnyPaginatedRequestOptions } from '../interfaces/companies.interfaces';

@Injectable({
  providedIn: 'root'
})
export class CompaniesV2Service extends CachedDataServiceV2<Company> {

  public readonly DEFAULT_PAGE_SIZE = 25;
  public readonly PAGE_SIZE_OPTIONS = [10, 25, 50]

  private DEFAULT_SORT_KEY = 'deviceConfig.vendor';
  private DEFAULT_SORT_DIRECTION = 'asc' as SortDirection;

  public readonly _data = new BehaviorSubject<Company[]>([]);

  private paginator?: MatPaginator;
  private _page = new BehaviorSubject<PageEvent>({ length, pageIndex: 0, pageSize: this.DEFAULT_PAGE_SIZE, previousPageIndex: 0 });
  private sort?: MatSort;
  private _sort = new BehaviorSubject<Sort>({ active: this.DEFAULT_SORT_KEY, direction: this.DEFAULT_SORT_DIRECTION });
  private _subscriptions = new Map<string, Subscription>();

  private _reload = new Subject<boolean>();
  public reload$ = this._reload.pipe(startWith(true), debounceTime(500));

  private $companyFilterOptions = new BehaviorSubject<CompaniesFilterOptions>({});
  public filters$ = this.$companyFilterOptions.asObservable();

  private readonly $active = new ReplaySubject<Company>(1);
  public active$ = this.$active.pipe(shareReplay(1));

  private readonly $partners = new BehaviorSubject<Company[]>([]);
  public partners$ = this.$partners.asObservable();

  private readonly $tabIndex = new ReplaySubject<number>(1);
  public tabIndex$ = this.$tabIndex.pipe(startWith(0), shareReplay(1));

  constructor(protected override http: HttpClient) {
    super(
      http,
      'company'
    );

    this._subscriptions.set(
      'table_state',
      combineLatest([
        this.filters$,
        this._page,
        this._sort,
        this.reload$
      ])
        .pipe(
          distinctUntilChanged(),
          debounceTime(100) // Small debounce to prevent multiple backend requests.
        )
        .subscribe(([filterOptions]) => {
          const options = this.createRequestOptions(filterOptions);
          this.loadData(options);
        })
    )
  }

  setTabIndex(index: number) {
    this.$tabIndex.next(index);
  }

  // Get all companies using the search endpoint loop until all data is fetched.
  public getAllCompanies(params?: Params): Observable<Company[]> {
    const take = 500;
    let page = 1;
    return this.search({ params: { ...params, page, take } }).pipe(
      expand((res) => {
        if (res?.meta?.hasNextPage) {
          page++;
          return this.search({ params: { page, take } });
        } else {
          return EMPTY; // Stop expanding, i. e. no more requests.
        }
      }),
      map((response) => (response ? response.data : [])),
      toArray(),
      map((allPages) => allPages.flat()),
    );
  }

  create(item: Company, options?: DataServiceRequestOptions): Observable<Company> {
    return super.cachedCreate(item, { endpoint: 'company', params: options?.params, apiVersion: 2 })
  }

  search(options?: DataServiceRequestOptions) {
    return super.cachedSearch({ resource: 'company', endpoint: 'company/search', params: options?.params, apiVersion: 2 })
  }

  loadData(options?: ComapnyPaginatedRequestOptions) {
    return this.cachedSearch({ resource: 'company', endpoint: 'company/list', params: options as Params, apiVersion: 2 }).pipe(
      take(1))
      .subscribe({
        next: res => {
          this._data.next(res.data);
          if (this.paginator) {
            this.paginator.length = res.meta.itemCount;
          }
        }
      });
  }

  usePaginator(paginator: MatPaginator) {
    this.paginator = paginator;
    // listen to paginator changes
    this._subscriptions.set('page', this.paginator.page.subscribe((page) => {
      this._page.next(page);
    }));
  }

  useSort(sort: MatSort) {
    this.sort = sort;
    // Disable clear, i. e. only allow asc or desc.
    this.sort.disableClear = true;
    // Listen to sorting changes
    this._subscriptions.set('sort', this.sort.sortChange
      .pipe(
        tap(() => {
          // reset paginator when sorting changes
          if (this.paginator) {
            this.paginator.pageIndex = 0;
          }
        }))
      .subscribe(sort => {
        this._sort.next(sort);
      }));
  }

  setFilterOptions(filterOptions: CompaniesFilterOptions) {
    // If filters change we should reset page
    if (this.paginator) {
      this.paginator.pageIndex = 0;
      this._page.next(Object.assign(this._page.value, { pageIndex: 0, previousPageIndex: 0 }))
    }
    this.$companyFilterOptions.next(Object.assign({}, this.$companyFilterOptions.value, filterOptions));
  }

  createRequestOptions(options: CompaniesFilterOptions): ComapnyPaginatedRequestOptions {
    const page = this._page.value;
    const sort = this._sort.value;
    // Remove undefined properties.
    Object.keys(options).forEach(key =>
      options[key as keyof ComapnyPaginatedRequestOptions] === undefined && delete options[key as keyof ComapnyPaginatedRequestOptions])
    return Object.assign(
      { page: page.pageIndex + 1, take: page.pageSize },
      { sortField: sort.active, sortOrder: sort.direction },
      options
    );
  }

  patch(item: Company, options?: DataServiceRequestOptions): Observable<Company> {
    return super.cachedPatch(item, { id: options?.id as string, resource: 'company', endpoint: 'company', params: options?.params, apiVersion: 2 })
      .pipe(tap((item) => this.$active.next(item)));
  }

  get(options?: DataServiceRequestOptions): Observable<Company> {
    return this.requestGet<Company>({ id: options?.id as string, resource: 'company', endpoint: 'company', params: options?.params, apiVersion: 2 })
      .pipe(tap((company) => this.$active.next(company)));
  }

  getUserCompany(options?: DataServiceRequestOptions): Observable<Company> {
    return this.requestGet<Company>({ id: options?.id as string, resource: 'company', endpoint: 'company', params: options?.params, apiVersion: 2 })
  }

  open(id: string): void {
    this.get({ id }).pipe(take(1)).subscribe()
  }

  delete(options?: DataServiceRequestOptions): Observable<Company> {
    return super.cachedDelete({ id: options?.id as string, resource: 'company', endpoint: 'company', apiVersion: 2 }).pipe(
      tap(() => this._reload.next(true))
    )
  }

  getPartners(projectId: string): Observable<Company[]> {
    return this.requestGet<Company[]>({ id: 'none', resource: 'company', endpoint: 'company/search/project/partners/', params: { projectId }, addId: false, apiVersion: 2 })
      .pipe(tap((partners) => this.$partners.next(partners)));
  }

}

export interface CompaniesFilterOptions extends Record<string, unknown> {
  q?: string;
  isActive?: boolean;
  type?: string;
}


export const CompanyIsActive = [
  {
    title: 'companies.filters.show_all',
    value: undefined
  },
  {
    title: 'companies.filters.active',
    value: true
  },
  {
    title: 'companies.filters.inactive',
    value: false
  },
]

export const CompanyTypes = [
  {
    title: 'companies.filters.show_all',
    value: undefined
  },
  {
    title: 'companies.filters.ab',
    value: 'ab'
  },
  {
    title: 'companies.filters.kommun',
    value: 'kommun'
  },
]