import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { BlastingInitiative, Company, Initiative, InitiativeType, LocationsInInitiative, Measurement, NotificationLevel, PartnerCompaniesInInitiative, UserResponsible } from '@ramboll/rsedt-shared';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription, combineLatest, debounceTime, forkJoin, map, shareReplay, startWith, switchMap, take, tap } from 'rxjs';
import { CachedDataServiceV2 } from 'src/app/core/classes/cached.data.service.v2';
import { Params } from 'src/app/core/classes/data.service';
import { DataServiceRequestOptions, MetaData, PaginatedResponse } from 'src/app/core/classes/data.service.v2';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

export interface InitiativeFilterOptions extends Record<string, unknown> {
  q?: string;
  type?: string;
  userResponsible?: string;
  projectId?: string;
  fieldworkAreaId?: string;
  isActive?: string;
  useSearch?: boolean;
}

export interface InitiativePaginatedRequestOptions {
  isActive?: string | boolean;
  sortOrder?: 'asc' | 'desc' | '';
  sortField?: string;
  page?: number;
  take?: number;
  projectId?: string;
  type?: string; //initiativeType
  fieldworkAreaId?: string;
  q?: string;
  useSearch?: boolean
}

export interface InitiativeTypes {
  value: string
  displayText: string
}

export interface InitiativeMetaData {
  meta: MetaData;
  data: Initiative[];
}
@Injectable({
  providedIn: 'root'
})
export class InitiativesService2 extends CachedDataServiceV2<Initiative> implements OnDestroy {

  public readonly $data = new BehaviorSubject<Initiative[]>([]);
  public readonly data$ = this.$data.asObservable();

  public readonly $connectedInitiatives = new ReplaySubject<BlastingInitiative[] | null>(1);
  public readonly connectedInitiatives$ = this.$connectedInitiatives.asObservable();

  public readonly $initiativePartners = new BehaviorSubject<PartnerCompaniesInInitiative[]>([]);
  public readonly initiativePartners$ = this.$initiativePartners.asObservable();

  private _subscriptions = new Map<string, Subscription>();
  private readonly $active = new ReplaySubject<Initiative>(1);
  public active$ = this.$active.pipe(shareReplay(1));
  private $initiativeFilterOptions = new BehaviorSubject<InitiativeFilterOptions>({});
  public filters$ = this.$initiativeFilterOptions.asObservable();

  private _loading = new BehaviorSubject<boolean>(false);
  public loading$ = this._loading.asObservable();

  private paginator?: MatPaginator;
  public readonly DEFAULT_PAGE_SIZE = 20;
  private _page = new BehaviorSubject<PageEvent>({ length, pageIndex: 0, pageSize: this.DEFAULT_PAGE_SIZE, previousPageIndex: 0 });
  public readonly PAGE_SIZE_OPTIONS = [20, 50, 100]

  private DEFAULT_SORT_KEY = '';
  private DEFAULT_SORT_DIRECTION = 'asc' as SortDirection;
  private sort?: MatSort;
  private _sort = new BehaviorSubject<Sort>({ active: this.DEFAULT_SORT_KEY, direction: this.DEFAULT_SORT_DIRECTION });

  private _reload = new Subject<boolean>();
  public reload$ = this._reload.pipe(startWith(true), debounceTime(500));

  public initiativeType: InitiativeTypes[] = [
    { displayText: 'initiatives.filters.blasting', value: "blasting" },
    { displayText: 'initiatives.filters.energy_well', value: "energy_well" },
    { displayText: 'initiatives.filters.information', value: "point_of_interest" },
    { displayText: 'initiatives.filters.inspection', value: "building_inspection" },
    { displayText: 'initiatives.filters.measurement', value: "measurement" }
  ];

  public alarmLevel = [
    {
      type: 'measurement',
      options: [
        { value: 'noAlarm' },
        { value: NotificationLevel.NOTIFICATION },
        { value: NotificationLevel.WARNING },
        { value: NotificationLevel.ALARM },
      ]
    },
    {
      type: 'building_inspection',
      options: [
        { value: 'unknown' },
        { value: 'not_received_for_inspection' },
        { value: 'external_and_Internal' },
        { value: 'external' },
        { value: 'internal' },
        { value: 'internal_inspection_not_wanted' },
        { value: 'extExternal_and_Internal_not_wanted' },
        { value: 'inspection_planned' },
        { value: 'inspection_started' },
      ]
    },
    {
      type: 'energy_well',
      options: [
        { value: 'blasting' },
        { value: 'not_set' },
        { value: 'most_unlikely' },
        { value: 'unlikely' },
        { value: 'quite_likely' },
        { value: 'likely' }
      ]
    },
    {
      type:'blasting',
      options: [
        { value: 'blasting' },
        { type:'measurement' ,
          options: [
            { value: 'noAlarm' },
            { value: NotificationLevel.NOTIFICATION },
            { value: NotificationLevel.WARNING },
            { value: NotificationLevel.ALARM },
          ]},
        { type:'energy_well',
          options: [
            { value: 'blasting' },
            { value: 'not_started' },
            { value: 'planned' },
            { value: 'done' },
          ]
        },
      ],
    },
    {
      type: 'point_of_interest',
      options: [
        { value: 'not_planned' },
        { value: 'planned' },
        { value: 'overdue' },
        { value: 'done' },
      ]
    }
  ]

  public readonly $InitiativesInWorkarea = new BehaviorSubject<Initiative[]>([]);
  public readonly InitiativesInWorkarea$ = this.$InitiativesInWorkarea.asObservable();

  constructor(
    protected override http: HttpClient,
    protected snackbarService: SnackbarService,
    private sharedService: SharedService,
  ) {
    super(
      http,
      'initiative');
    this._subscriptions.set(
      'table_state',
      combineLatest([
        this.filters$,
        this._page,
        this._sort,
        this.reload$
      ])
        .pipe(
          debounceTime(100) // Small debounce to prevent multiple backend requests.
        )
        .subscribe(([filterOptions]) => {
          const options = this.createRequestOptions(filterOptions);
          this.loadData(options);
        })
    )
  }

  public readonly currentMeasurements$ = this.active$.pipe(
    switchMap((initiative) =>
      this.requestSearch<Measurement>({ resource: 'measurement', params: { projectId: initiative?.projectId as string, initiativeId: initiative.initiativeId, take: 500 }, endpoint: 'measurement/search/project', apiVersion: 2 })
        .pipe(map((res) => res.data))
    ),
    shareReplay()
  );

  public readonly connectedBlasting$ = this.connectedInitiatives$.pipe(
    switchMap((initiatives) => {
      const openObservables = initiatives?.map((initiative) =>
        this.requestSearch<LocationsInInitiative>({ resource: 'measurement', endpoint: 'location/search/initiative', params: { projectId: initiative?.projectId as string, initiativeId: initiative.initiativeId, take: 500 }, apiVersion: 2 }).pipe(
          debounceTime(300),
          map((res) => res.data)
        )
      );

      return forkJoin(openObservables as unknown as LocationsInInitiative[]).pipe(debounceTime(300),
        map((arrayOfArrays) => arrayOfArrays.reduce((acc, curr:any) => acc.concat(curr), []))
      );
    }),
    shareReplay()
  );

  search(options?: DataServiceRequestOptions): Observable<PaginatedResponse<Initiative>> {
    return this.cachedSearch({ resource: 'project', endpoint: 'initiative/search', params: options?.params, apiVersion: 2 });
  }

  getPartner(options?: DataServiceRequestOptions): Observable<Company> {
    return this.cachedGet({ id: options?.id as string, resource: 'company', endpoint: 'company', params: options?.params, apiVersion: 2 })
  }

  searchCompany(options?: DataServiceRequestOptions) {
    return super.cachedSearch({ resource: 'company', endpoint: 'company/search', params: options?.params, apiVersion: 2 })
  }

  list(options?: DataServiceRequestOptions): Observable<PaginatedResponse<Initiative>> {
    return this.cachedSearch({ resource: 'project', endpoint: 'initiative/list', params: options?.params, apiVersion: 2 });
  }

  getInitiativesByProjectId(options?: DataServiceRequestOptions): Observable<PaginatedResponse<Initiative>> {
    return this.cachedSearch({ resource: 'project', endpoint: 'initiative/search/project', params: options?.params, apiVersion: 2 });
  }

  getUsersResponsibleByProjectId(projectId: string): Observable<UserResponsible[]> {
    return this.requestGet<UserResponsible[]>({ id: 'none', resource: 'project', endpoint: `initiative/user-responsible/project/${projectId}`, addId: false, apiVersion: 2 });
  }

  getConnectedInitiatives(options?: DataServiceRequestOptions) {
    this.cachedSearch( { resource: 'project', endpoint: `initiative/${options?.id}/connected-initiatives`, params: options?.params, apiVersion: 2 } )
      .pipe(take(1)).subscribe(initiatives => this.$connectedInitiatives.next(initiatives.data))
  }

  getInitiativeMeasurementTypeIds(initiativeId: string) {
    return this.requestSearch<string>({
      resource: 'measurement',
      endpoint: `measurement/search/measurement-type-ids`,
      params: { initiativeId },
      apiVersion: 2,
    }).pipe(take(1));
  }

  getGeoJsonsInInitiative(id: string, documentType = 'geojson'): Observable<any> {
    return this.requestGet({ id, resource: 'project', endpoint: `initiative/${id}/files`, params: {take: 50, documentType}, apiVersion: 2, addId: false })
  }

  get(options?: DataServiceRequestOptions): Observable<Initiative> {
    return this.cachedGet({ id: options?.id as string, resource: 'project', endpoint: 'initiative', params: options?.params, apiVersion: 2 })
      .pipe(tap((initiative) => this.$active.next(initiative)));
  }

  open(id: string) {
    this.get({ id }).pipe(take(1)).subscribe((initiative: Initiative) => {
      this.$active.next(initiative)
      this.$initiativePartners.next(initiative.partnerCompanies || [])
    })
  }

  patch(item: Initiative, options?: DataServiceRequestOptions): Observable<Initiative> {
    return super.cachedPatch(item, { id: options?.id as string, resource: 'project', endpoint: 'initiative', params: options?.params, apiVersion: 2 })
      .pipe(tap((item) => {
        this.getConnectedInitiatives({id: item.initiativeId})
        return this.open(item.initiativeId)
      }))
  }

  create(item: Initiative, options?: DataServiceRequestOptions): Observable<Initiative> {
    return super.cachedCreate(item, { resource: 'project', endpoint: 'initiative', params: options?.params, apiVersion: 2 })
      .pipe(tap(() => this._reload.next(true)));
  }

  remove(options: DataServiceRequestOptions) {
    return super.cachedDelete({ id: options?.id as string, resource: 'project', endpoint: 'initiative', apiVersion: 2 }).pipe(
      tap(() => this._reload.next(true))
    )
  }

  setFilterOptions(filterOptions: InitiativeFilterOptions) {
    // If filters change we should reset page
    if (this.paginator) {
      this.paginator.pageIndex = 0;
      this._page.next(Object.assign(this._page.value, { pageIndex: 0, previousPageIndex: 0 }))
    }
    this.$initiativeFilterOptions.next(Object.assign({}, this.$initiativeFilterOptions.value, filterOptions));
  }

  loadData(options?: InitiativePaginatedRequestOptions) {
    this._loading.next(true);
    let initiativesRequest: Observable<PaginatedResponse<Initiative>>;
    if (options?.useSearch) {
      delete options.useSearch
      initiativesRequest = this.search({ params: options as unknown as Params });
    }
    else if (options?.projectId) {
      initiativesRequest = this.getInitiativesByProjectId({ params: options as unknown as Params });
    } else {
      initiativesRequest = this.list({ params: options as unknown as Params });
    }
    combineLatest([
      initiativesRequest,
      this.sharedService.raskProjects$.pipe(startWith(undefined))
    ]).subscribe({
      next: ([res, raskProjects]) => {
        res.data.forEach(initiative => {
          if (initiative.type === InitiativeType.BUILDING_INSPECTION) {
            initiative.numLocations = raskProjects?.find(r => r.projnr === initiative.raskProjectNumber)?.antobj || 0;
          }
        })
        this.$data.next(res.data);
        this._loading.next(false)
        if (this.paginator) {
          this.paginator.length = res.meta.itemCount;
        }
      },
      error: () => {
        this._loading.next(false)
        this.snackbarService.genericErrorGet()
      }
    });
  }

  createRequestOptions(options: InitiativeFilterOptions): InitiativePaginatedRequestOptions {
    const page = this._page.value;
    const sort = this._sort.value;
    // Remove undefined properties.
    Object.keys(options).forEach(key =>
      options[key as keyof InitiativePaginatedRequestOptions] === undefined && delete options[key as keyof InitiativePaginatedRequestOptions])
    return Object.assign(
      { page: page.pageIndex + 1, take: page.pageSize },
      { sortField: sort.active, sortOrder: sort.direction },
      options
    );
  }

  usePaginator(paginator: MatPaginator) {
    this.paginator = paginator;
    // listen to paginator changes
    this._subscriptions.set('page', this.paginator.page.subscribe((page) => {
      this._page.next(page);
    }));
  }

  useSort(sort: MatSort) {
    this.sort = sort;
    // Disable clear, i. e. only allow asc or desc.
    this.sort.disableClear = true;
    // Listen to sorting changes
    this._subscriptions.set('sort', this.sort.sortChange
      .pipe(
        tap(() => {
          // reset paginator when sorting changes
          if (this.paginator) {
            this.paginator.pageIndex = 0;
          }
        }))
      .subscribe(sort => {
        this._sort.next(sort);
      }));
  }

  getInitiativesInWorkarea(workareaId: string) {
    this.search({ params: { fieldworkAreaId: workareaId, take: 200 } }).pipe(take(1)).subscribe((res) => {
      this.$InitiativesInWorkarea.next(res.data)
    })
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}

