import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ID } from '../interfaces/model.interface';
import { NotificationService } from '../services/notification.service';

export type Params = Record<string, string | string[] | number | boolean>;

export interface DataServiceRequestOptions {
  id?: ID;
  resource?: string;
  ignoreId?: boolean;
  params?: Params;
  addIdToResource?: boolean;
  skipRefresh?: boolean;
  apiVersion?: number;
  skipCache?: boolean;
}

export interface DataServiceIdRequestOptions extends DataServiceRequestOptions {
  id: ID;
}

export abstract class DataService {

  protected readonly $errors: ReplaySubject<string | void> = new ReplaySubject(1);
  public readonly errors$: Observable<string | void> = this.$errors.asObservable();

  constructor(
    protected http: HttpClient,
    protected notificiationService: NotificationService,
    protected resource = '/'
  ) { }

  protected errorHandler(error: HttpErrorResponse | Error, options: DataServiceRequestOptions) {
    if (error instanceof Error) {
      // these errors are generated in DataService
      this.$errors.next(`${error.name}: ${error.message}`);
      this.notificiationService.error(`Error occured during request to ${options.resource || this.resource} (${error.name}: ${error.message})`);
      return throwError(() => new Error(`DataService: Error occured: ${error.message}`))
    } else if (error instanceof HttpErrorResponse && error.error instanceof ErrorEvent) {
      // ErrorEvents are generated by the browser (network errors, etc)
      this.$errors.next(`${error.error.type}: ${error.error.message}`);
      this.notificiationService.error(`Error occured during request to ${options.resource || this.resource} (${error.error.type}: ${error.error.message})`);
    } else {
      // HttpErrorResponse are from the server (500 internal server error, 404 not found, etc)
      this.$errors.next(`${error.status} ${error.statusText}: ${error.url}`);
      this.notificiationService.http(this.resource, error);
    }
    console.error(`DataService: Error on ${this.resource}: ${error.status} ${error.statusText} (${error.url})`);
    return throwError(() => new Error(`DataService: Error on ${this.resource}: ${error.status} ${error.statusText} (${error.url})`))
    // return throwError(`DataService: Error on ${this.resource}: ${error.status} ${error.statusText} (${error.url})`);
    // return a non-error observable here to "hide" errors. for example: return of([]);
  }

  protected requestGetAll<S>(options: DataServiceRequestOptions = {}): Observable<S[]> {
    return this.http
      .get<S[]>(this.generateUrl(options), { params: options.params })
      .pipe(
        catchError(error => this.errorHandler(error, options)),
        tap(() => this.$errors.next()),
      );
  }

  protected requestGet<T>(options: DataServiceIdRequestOptions): Observable<T> {
    if (!options.id) {
      throw new Error('DataService.requestGet(): ID required.');
    }

    return this.http
      .get<T>(this.generateUrl(options), { params: options.params })
      .pipe(
        catchError(error => this.errorHandler(error, options)),
        tap(() => this.$errors.next()),
      );
  }

  protected requestCreate<T>(data: T, options: DataServiceRequestOptions = {}): Observable<T> {
    return this.http.post<T>(this.generateUrl(options), data, { params: options.params })
      .pipe(
        catchError(error => this.errorHandler(error, options)),
        tap(() => this.$errors.next()),
      );
  }

  protected requestUpdate<T>(data: T, options: DataServiceIdRequestOptions): Observable<T> {
    if (!options.id) {
      throw new Error('DataService.requestUpdate(): ID required.');
    }

    return this.http.put<T>(this.generateUrl(options), data, { params: options.params })
      .pipe(
        catchError(error => this.errorHandler(error, options)),
        tap(() => this.$errors.next()),
      );
  }

  protected requestPatch<T>(data: Partial<T>, options: DataServiceIdRequestOptions): Observable<T> {
    if (!options.id) {
      throw new Error('DataService.requestPatch(): ID required.');
    }

    return this.http.patch<T>(this.generateUrl(options), data, { params: options.params })
      .pipe(
        catchError(error => this.errorHandler(error, options)),
        tap(() => this.$errors.next()),
      );
  }

  protected requestDelete<T>(options: DataServiceIdRequestOptions) {
    if (!options.id) {
      throw new Error('DataService.requestDelete(): ID required.');
    }

    return this.http.delete<T>(this.generateUrl(options), options)
      .pipe(
        catchError(error => this.errorHandler(error, options)),
        tap(() => this.$errors.next()),
      );
  }

  private generateUrl({ id, resource = this.resource, addIdToResource = true, apiVersion = 1 }: DataServiceRequestOptions | DataServiceIdRequestOptions) {
    return [environment.API_BASE_URL, environment.localhost || apiVersion === 1 ? `v${apiVersion}` : '', resource, addIdToResource && encodeURIComponent(id || '')]
      .filter(x => !!x).join('/').split('://').map(p => p.replace(/\/\//, '/')).join('://');
  }

}
