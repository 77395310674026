import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { User, UserResponsible } from '@ramboll/rsedt-shared';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject, Subscription, combineLatest, debounceTime, expand, reduce, take, tap } from 'rxjs';
import { CachedDataServiceV2 } from 'src/app/core/classes/cached.data.service.v2';
import { DataServiceRequestOptions } from 'src/app/core/classes/data.service';
import { MetaData, PaginatedResponse } from 'src/app/core/classes/data.service.v2';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

export interface UsersFilterOptions extends Record<string, unknown> {
  q?: string;
  isActive?: boolean;
  companyType?: string;
  projectId?: string;
  companyId?: string;
}

export interface usersPaginatedRequestOptions {
  sortOrder?: 'asc' | 'desc' | '';
  sortField?: string;
  page?: number;
  take?: number;
  q?: string;
  isActive?: boolean;
}

export interface usersMetaData {
  meta: MetaData;
  data: User[];
}

@Injectable({
  providedIn: 'root'
})
export class User2Service extends CachedDataServiceV2<User> implements OnDestroy {

  public readonly $data = new BehaviorSubject<User[]>([]);
  public readonly data$ = this.$data.asObservable();

  private readonly $activeUser = new BehaviorSubject<User | undefined>(undefined);
  public active$ = this.$activeUser.asObservable();

  private $usersFilterOptions = new BehaviorSubject<UsersFilterOptions>({});
  public filters$ = this.$usersFilterOptions.asObservable();

  private paginator?: MatPaginator;
  public readonly DEFAULT_PAGE_SIZE = 20;
  private _page = new BehaviorSubject<PageEvent>({ length, pageIndex: 0, pageSize: this.DEFAULT_PAGE_SIZE, previousPageIndex: 0 });
  public readonly PAGE_SIZE_OPTIONS = [20, 50, 100]

  private _loading = new BehaviorSubject<boolean>(false);
  public loading$ = this._loading.asObservable();

  private DEFAULT_SORT_KEY = '';
  private DEFAULT_SORT_DIRECTION = 'asc' as SortDirection;
  private sort?: MatSort;
  private _sort = new BehaviorSubject<Sort>({ active: this.DEFAULT_SORT_KEY, direction: this.DEFAULT_SORT_DIRECTION });

  private _reload = new BehaviorSubject<boolean>(true);
  public reload$ = this._reload.pipe(debounceTime(500));
  public readonly usersResponsible$ = this.requestGet<UserResponsible[]>({ id: 'none', resource: 'project', endpoint: 'initiative/user-responsible', addId: false, apiVersion: 2 });

  private _subscriptions = new Map<string, Subscription>();

  private userSource = new ReplaySubject<User>(1);
  public selectedUser$ = this.userSource.asObservable();
  setSelectedUser(user: User) {
    this.userSource.next(user);
  }

  private detailsForm!: FormGroup;

  setDetailsForm(detailsForm: FormGroup) {
    this.detailsForm = detailsForm;
  }

  getDetailsForm(): FormGroup {
    return this.detailsForm;
  }

  public readonly allUsers$ = this.search({ params: { page: 1, take: 500 } }).pipe(
    expand((res) =>
      res.meta.hasNextPage
        ? this.search({ params: { page: res.meta.page + 1, take: 500 } })
        : EMPTY
    ),
    reduce((acc, current) => acc.concat(current.data), [] as User[])
  );

  constructor(
    protected override http: HttpClient,
    protected snackbarService: SnackbarService
  ) {
    super(
      http,
      'users'
    );
    this._subscriptions.set(
      'table_state',
      combineLatest([
        this.filters$,
        this._page,
        this._sort,
        this.reload$
      ])
        .pipe(
          debounceTime(100) // Small debounce to prevent multiple backend requests.
        )
        .subscribe(([filterOptions]) => {
          const options = this.createRequestOptions(filterOptions);
          this.loadData(options);
        })
    )
  }

  loadData(options?: usersPaginatedRequestOptions) {
    this._loading.next(true);
    this.requestGet<usersMetaData>({ id: 'none', resource: 'company', endpoint: 'user/search', params: options as Record<string, string | string[] | number | boolean>, addId: false, apiVersion: 2 })
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.$data.next(res.data);
          this._loading.next(false)
          if (this.paginator) {
            this.paginator.length = res.meta.itemCount;
          }
        },
        error: () => {
          this._loading.next(false)
          this.snackbarService.genericErrorGet()
        }
      });
  }

  search(options?: DataServiceRequestOptions): Observable<PaginatedResponse<User>> {
    return this.requestSearch({ resource: 'company', endpoint: 'user/search', params: options?.params, apiVersion: 2 });
  }

  patch(user: User): Observable<User> {
    return this.cachedPatch(user, { id: (user as any)._id, resource: 'company', endpoint: 'user', apiVersion: 2 }).pipe(tap(() => this._reload.next(!this._reload.value)))
  }

  get(options?: DataServiceRequestOptions): Observable<User> {
    return this.cachedGet({ id: options?.id as string, resource: 'company', endpoint: 'user', params: { settings: true }, apiVersion: 2 })
      .pipe(tap((user) => this.$activeUser.next(user)));
  }

  createRequestOptions(options: UsersFilterOptions): usersPaginatedRequestOptions {
    const page = this._page.value;
    const sort = this._sort.value;
    // Remove undefined properties.
    Object.keys(options).forEach(key =>
      options[key as keyof usersPaginatedRequestOptions] === undefined && delete options[key as keyof usersPaginatedRequestOptions])
    return Object.assign(
      { page: page.pageIndex + 1, take: page.pageSize },
      { sortField: sort.active, sortOrder: sort.direction },
      options
    );
  }

  create(user: User): Observable<User> {
    return super.cachedCreate(user, { resource: 'company', endpoint: 'user', apiVersion: 2 }).pipe(tap(() => this._reload.next(!this._reload.value)))
  }

  remove(id: string) {
    return this.cachedDelete({ id, resource: 'company', endpoint: 'user', apiVersion: 2 }).pipe(tap(() => this._reload.next(!this._reload.value)))
  }

  setFilterOptions(filterOptions: UsersFilterOptions) {
    // If filters change we should reset page
    if (this.paginator) {
      this.paginator.pageIndex = 0;
      this._page.next(Object.assign(this._page.value, { pageIndex: 0, previousPageIndex: 0 }))
    }
    this.$usersFilterOptions.next(Object.assign({}, this.$usersFilterOptions.value, filterOptions));
  }

  usePaginator(paginator: MatPaginator) {
    this.paginator = paginator;
    // listen to paginator changes
    this._subscriptions.set('page', this.paginator.page.subscribe((page) => {
      this._page.next(page);
    }));
  }

  useSort(sort: MatSort) {
    this.sort = sort;
    // Disable clear, i. e. only allow asc or desc.
    this.sort.disableClear = true;
    // Listen to sorting changes
    this._subscriptions.set('sort', this.sort.sortChange
      .pipe(
        tap(() => {
          // reset paginator when sorting changes
          if (this.paginator) {
            this.paginator.pageIndex = 0;
          }
        }))
      .subscribe(sort => {
        this._sort.next(sort);
      }));
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
