import { ClientCompanyType, NotificationSettings } from "@ramboll/rsedt-shared";
import { Company } from "../models/company";

export class AbstractUser {
  userId: string;
  objectId?: string;
  companyId?: string;
  company?: Company;
  companyName?: string;
  companyType?: ClientCompanyType;
  userName: string;
  userLocation?: string;
  title?: string;
  mail?: string;
  officePhone?: string;
  mobilePhone?: string;
  active?: boolean;
  gdprApproved?: boolean;
  role?: UserRole | string;
  locked?: boolean;
  approvedEula?: boolean;
  invitationPending?: boolean;
  invitationApprovalDate?: Date;
  invitationChecksum?: string;
  notificationSetting?: NotificationSettings;
  createdBy?: string;
  createdAt?: Date;
  updatedAt?: Date;
  contactByCall?: boolean;
  contactByEmail?: boolean;
  contactByLetter?: boolean;
  constructor(input: AbstractUser) {
    this.userId = input.userId;
    this.objectId = input.objectId;
    this.companyId = input.companyId;
    this.company = input.company;
    this.companyName = input.companyName;
    this.userName = input.userName;
    this.userLocation = input.userLocation;
    this.company = input.company;
    this.title = input.title;
    this.mail = input.mail;
    this.officePhone = input.officePhone;
    this.mobilePhone = input.mobilePhone;
    this.active = input.active;
    this.gdprApproved = input.gdprApproved;
    this.role = input.role;
    this.locked = input.locked;
    this.approvedEula = input.approvedEula;
    this.invitationPending = input.invitationPending;
    this.invitationApprovalDate = input.invitationApprovalDate;
    this.invitationChecksum = input.invitationChecksum;
    this.notificationSetting = input.notificationSetting;
    this.createdBy = input.createdBy;
    this.createdAt = input.createdAt;
    this.updatedAt = input.updatedAt;
    this.contactByCall = input.contactByCall;
    this.contactByEmail = input.contactByEmail;
    this.contactByLetter = input.contactByLetter;
  }
}

// TODO Check if ok.
export enum UserRole {
  PortalUser = 'users.roles.portal_user',
  PartnerUser = 'users.roles.partner_user',
  Administrator = 'users.roles.administrator',
  SuperAdmin = 'users.roles.super_admin',
  Engineer = 'users.roles.engineer', // Not used kept for reference
  FieldWorker = 'users.roles.field_worker', // Not used kept for reference
  Manager = 'users.roles.manager', // Not used kept for reference
  NotSet = 'users.roles.not_set' // Not used kept for reference
}