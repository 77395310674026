import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { Location, LocationObject, LocationObjectRelationMap, LocationType } from '@ramboll/rsedt-shared';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, lastValueFrom, map, Observable, ReplaySubject, shareReplay, Subscription, switchMap, take, tap } from 'rxjs';
import { CachedDataServiceV2 } from 'src/app/core/classes/cached.data.service.v2';
import { DataServiceRequestOptions, PaginatedResponse } from 'src/app/core/classes/data.service.v2';
import { LocationMetaData, LocationPaginatedRequestOptions } from 'src/app/devices/interfaces/locations.interface';
import { LocationObjectsService2 } from './location-objects.service2';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { InitiativesService2 } from 'src/app/initiatives/services/initiatives.service2';

@Injectable({
  providedIn: 'root'
})
export class LocationsService2 extends CachedDataServiceV2<Location> implements OnDestroy {

  private _subscriptions = new Map<string, Subscription>();

  public readonly $data = new BehaviorSubject<Location[]>([]);
  public readonly data$ = this.$data.asObservable();

  private _loading = new BehaviorSubject<boolean>(false)
  public loading$ = this._loading.asObservable()

  private readonly _columnsToDisplay = new BehaviorSubject<string[]>([]);
  public columnsToDisplay$ = this._columnsToDisplay.asObservable();
  public setColumnsToDisplay(columns: string[]) {
    this._columnsToDisplay.next(columns);
  }

  private readonly _locationTabsToDisplay = new BehaviorSubject<{ label: string; link: string; }[]>([]);
  public locationTabsToDisplay$ = this._locationTabsToDisplay.asObservable();
  public setLocationTabsToDisplay(tabs: { label: string; link: string; }[]) {
    this._locationTabsToDisplay.next(tabs);
  }

  private paginator?: MatPaginator;
  public readonly DEFAULT_PAGE_SIZE = 50;
  private _page = new BehaviorSubject<PageEvent>({ length, pageIndex: 0, pageSize: this.DEFAULT_PAGE_SIZE, previousPageIndex: 0 });
  public readonly PAGE_SIZE_OPTIONS = [25, 50, 100]

  private DEFAULT_SORT_KEY = '';
  private DEFAULT_SORT_DIRECTION = 'asc' as SortDirection;
  private sort?: MatSort;
  private _sort = new BehaviorSubject<Sort>({ active: this.DEFAULT_SORT_KEY, direction: this.DEFAULT_SORT_DIRECTION });

  private $locationsFilterOptions = new BehaviorSubject<LocationsFilterOptions>({});
  public filters$ = this.$locationsFilterOptions.asObservable();

  public _reload = new BehaviorSubject<boolean>(true);
  private reload$ = this._reload.pipe(debounceTime(500));
  public triggerReload() {
    this._reload.next(true);
  }

  private _subEntity = new BehaviorSubject<LocationSubEntity>('Locations')
  public readonly subEntity$ = this._subEntity.asObservable()

  public setSubEntity(subEntity: LocationSubEntity) {
    this._subEntity.next(subEntity)
    let Entity = ''
    if (subEntity === 'information') {
      Entity = "point_of_interest"
    } else if (subEntity === 'energy-wells') {
      Entity = "energy_well"
    } else if (subEntity === 'Blastings') {
      Entity = "blasting"
    } else {
      Entity = subEntity
    }

    const currentFilter = this.$locationsFilterOptions.getValue();
    const option: LocationsFilterOptions = {
      type: [Entity]
    }

    const mergedFilter = { ...currentFilter, ...option }

    this.$locationsFilterOptions.next(mergedFilter)
  }

  /* other */
  private readonly $active = new ReplaySubject<Location>(1);
  public readonly active$ = this.$active.pipe(shareReplay(1));


  public readonly currentTypingObject$ = this.active$.pipe(
    map(location => location.objects?.[0])
  )

  public readonly currentStructureObject$ = this.active$.pipe(
    map(location => location.objects?.[0]?.objects?.[0])
  )

  public readonly validStructureObjects$ = this.currentTypingObject$.pipe(
    map(typingObject => LocationObjectRelationMap.get(typingObject?.objectType.typeId || '')?.filter(item => item !== undefined))
  )

  public readonly typeIsLocked$ = this.active$.pipe(
    map((location: Location) => {
      //if location has a structure (eg. metal cabinet)
      if (location.objects?.[0]?.objects?.[0]) {
        return 'locked'
      }
      //if location doesn't have structure, there is no device installed so it's safe to change/remove location type
      return 'unlocked'
    }),
    shareReplay(1),
  );

  constructor(
    protected override http: HttpClient,
    protected locationObjectsService: LocationObjectsService2,
    protected initiativesService: InitiativesService2,
    protected snackbarService: SnackbarService
  ) {
    super(
      http,
      'location'
    );
    this._subscriptions.set(
      'table_state',
      combineLatest([
        this.filters$,
        this.subEntity$,
        this._page,
        this._sort,
        this.reload$,
      ])
        .pipe(
          distinctUntilChanged(),
          debounceTime(100) // Small debounce to prevent multiple backend requests.
        )
        .subscribe(([filterOptions, locationType]) => {
          // Load data with options
          const options = this.createRequestOptions(filterOptions);
          this.loadData(options, locationType);
        })
    )
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  get(options?: DataServiceRequestOptions): Observable<Location> {
    return this.requestGet<Location>({ id: options?.id as string, resource: 'measurement', endpoint: 'location', suffix: 'expanded', params: options?.params, apiVersion: 2 })
      .pipe(tap((location) => this.$active.next(location)));
  }

  open(id: string): void {
    this.get({ id }).pipe(take(1)).subscribe((location: Location) => {
      this.$active.next(location)
    })
  }

  searchByProject(options?: DataServiceRequestOptions): Observable<PaginatedResponse<Location>> {
    return this.requestSearch({ resource: 'measurement', endpoint: 'location/search/project', params: options?.params, apiVersion: 2 });
  }

  getLocationAreasListByProject(params: { projectId: string}): Observable<{ data: string[] }> {
    const options = { resource: 'measurement', endpoint: 'location/search/project/areasnames', apiVersion: 2 }
    return this.http.get<{ data: string[] }>(this.generateUrl(options), { params });
  }

  delete(options?: DataServiceRequestOptions): Observable<Location> {
    return super.cachedDelete({ id: options?.id as string, resource: 'measurement', endpoint: 'location', apiVersion: 2 })
      .pipe(tap(() => this._reload.next(!this._reload.value)))
  }

  createLocation(item: Location, options?: DataServiceRequestOptions): Observable<Location> {
    return super.cachedCreate(item, { resource: 'measurement', endpoint: 'location', params: options?.params, apiVersion: 2 })
      .pipe(tap(() => {
        this.$active.next(item);
        this._reload.next(true);
      }));
  }

  addLocationObject(object: LocationObject): Observable<Location> {
    return this.active$.pipe(
      switchMap(location => this.locationObjectsService.removeAll(location, 'type')),
      take(1),
      switchMap(() => this.locationObjectsService.create(object)),
      take(1),
      switchMap(() => this.active$),
      take(1),
      switchMap(location => this.get({ id: location.locationId })),
      take(1)
    ).pipe(tap((res) => this.$active.next(res)));
  }

  updateLocationObject(object: LocationObject): Observable<Location> {
    return this.locationObjectsService.patch(object, { id: object.objectId })
      .pipe(
        switchMap(() => {
          return this.active$
        }),
        take(1),
        switchMap(location => {
          return this.get({ id: location.locationId })
        }),
        tap((res) => this.$active.next(res))
      );
  }

  addLocationObjectStructure(object: LocationObject): Observable<Location> {
    return this.active$.pipe(
      switchMap(location => this.locationObjectsService.removeAll(location, 'structure')),
      take(1),
      switchMap(() => this.locationObjectsService.create(object)),
      take(1),
      switchMap(() => this.active$),
      take(1),
      switchMap(location => this.get({ id: location.locationId })),
      take(1)
    ).pipe(tap((res) => this.$active.next(res)));
  }

  updateLocationObjectStructure(object: LocationObject): Observable<Location> {
    return this.active$.pipe(
      switchMap(() => this.locationObjectsService.patch(object, { id: object.objectId })),
      take(1),
      switchMap(() => this.active$),
      take(1),
      switchMap(location => this.get({ id: location.locationId })),
      take(1)
    ).pipe(tap((res) => this.$active.next(res)));
  }

  removeLocationObjectStructure(): Observable<Location> {
    return this.active$.pipe(
      switchMap(location => this.locationObjectsService.removeAll(location, 'structure')),
      take(1),
      switchMap(() => this.active$),
      take(1),
      switchMap(location => this.get({ id: location.locationId })),
      take(1)
    ).pipe(tap((res) => this.$active.next(res)));
  }

  patch(item: Location, options?: DataServiceRequestOptions): Observable<Location> {
    return super.cachedPatch(item, { id: options?.id as string, resource: 'measurement', endpoint: 'location', params: options?.params, apiVersion: 2 })
      .pipe(tap((item) => this.get({ id: item.locationId })));
  }

  async loadData(options?: LocationPaginatedRequestOptions, locationType?: LocationSubEntity) {
    this._loading.next(true);

    // Use filterOption 'list = false' to use /search endpoint
    let resource = 'measurement';
    let endpoint = 'location/list';
    if (options) {
      if (options?.list === false) {
        endpoint = 'location/search'
      }
      delete options.list
    }

    switch (locationType) {
      case 'inspections':
        // If inspections we should use rask api instead and verify that a projectNumber or project id is available.
        if (options?.projectId) {
          // We don't know what rask initiatives the project contain, so first we make a request to find out. Then assing to options.
          options.projectNumber = await lastValueFrom(
            this.initiativesService.cachedSearch({ resource: 'project', endpoint: 'initiative/search/project', params: { projectId: options.projectId, type: LocationType.BUILDING_INSPECTION, take: 500 } })
              .pipe(map(initiatives => initiatives.data.map(initiative => initiative.raskProjectNumber as string)))
          )
          if (!options?.projectNumber?.length) {
            this._loading.next(false);
            this.$data.next([]);
            return;
          }
        } else if (!options?.projectNumber) {
          this._loading.next(false);
          this.$data.next([]);
          return;
        }
        resource = 'rask-proxy';
        endpoint = 'search';
        options.sortField = options.sortField || 'address';
        break;
      case 'Blastings':
      case 'energy-wells':
      case 'information':
        // In projects we should use other endpoint.
        if (options?.projectId) {
          endpoint = 'location/search/project';
        }
        break;
      case 'Locations':
        if (options && !options?.type?.includes('energy_well')) {
          // this filters out unwanted location types "leaking" location-list view (excluding observation points etc), by adding query param type: "measurement"
          options.type = ["measurement"]
        }
        break;
      default:
        break;
    }
    // If noise or vibration we should add correct measurement types
    if (options?.measurementType === 'NOISE') {
      options.measurementType = ['NOISE_PEAK_LEVEL', 'NOISE_EQUIVALENT_LEVEL'];
    }
    if (options?.measurementType === 'VIBRATION') {
      options.measurementType = ['GROUND_VIBRATION_VERTICAL', 'GROUND_VIBRATION_LONGITUDINAL', 'GROUND_VIBRATION_TRANSVERSAL'];
    }

    const requestData = { id: 'none', resource, endpoint, params: options as Record<string, string | string[] | number | boolean>, addId: false, apiVersion: 2 }

    this.cachedGet(requestData)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.$data.next((res as unknown as LocationMetaData).data);
          this._loading.next(false)
          if (this.paginator) {
            this.paginator.length = (res as unknown as LocationMetaData).meta.itemCount;
          }
        },
        error: () => {
          this._loading.next(false)
          this.$data.next([]);
          this.snackbarService.genericErrorGet()
        }
      });
  }

  setFilterOptions(filterOptions: LocationsFilterOptions) {
    // If filters change we should reset page
    if (this.paginator) {
      this.paginator.pageIndex = 0;
      this._page.next(Object.assign(this._page.value, { pageIndex: 0, previousPageIndex: 0 }))
    }
    this.$locationsFilterOptions.next(Object.assign({}, this.$locationsFilterOptions.value, filterOptions));
  }

  createRequestOptions(options: LocationsFilterOptions): LocationPaginatedRequestOptions {
    const page = this._page.value;
    const sort = this._sort.value;
    // Add timestamp as default sort for blastings
    if (options.type?.includes('blasting') && sort.active === this.DEFAULT_SORT_KEY) {
      sort.active = 'timestamp';
      sort.direction = 'desc';
    }
    // Remove undefined properties.
    Object.keys(options).forEach(key =>
      options[key as keyof LocationPaginatedRequestOptions] === undefined && delete options[key as keyof LocationPaginatedRequestOptions])
    return Object.assign(
      { page: page.pageIndex + 1, take: page.pageSize },
      { sortField: sort.active, sortOrder: sort.direction },
      options
    );
  }

  usePaginator(paginator: MatPaginator) {
    this.paginator = paginator;
    // listen to paginator changes
    this._subscriptions.set('page', this.paginator.page.subscribe((page) => {
      this._page.next(page);
    }));
  }

  useSort(sort: MatSort) {
    this.sort = sort;
    // Disable clear, i. e. only allow asc or desc.
    this.sort.disableClear = true;
    // Listen to sorting changes
    this._subscriptions.set('sort', this.sort.sortChange
      .pipe(
        tap(() => {
          // reset paginator when sorting changes
          if (this.paginator) {
            this.paginator.pageIndex = 0;
          }
        }))
      .subscribe(sort => {
        this._sort.next(sort);
      }));
  }
}

export interface LocationsFilterOptions extends Record<string, unknown> {
  q?: string;
  isActive?: boolean;
  measurementType?: string;
  timestampFr?: string;
  timestampTo?: string;
  status?: string;
  initiativeId?: string;
  measurement?: string;
  type?: string[];
  riskLevel?: string;
  list?: boolean;
  projectNumber?: string;
  reportCreated?: string;
  statusCode?: string;
  locationType?: LocationType;
  projectId?: string;
  freeText?: string;
  isManual?: boolean;
}

export const LocationIsActive = [
  {
    title: 'Show all',
    value: undefined
  },
  {
    title: 'Active',
    value: true
  },
  {
    title: 'Inactive',
    value: false
  },
]

export type LocationSubEntity =
'Blastings' |
'energy-wells' |
'information' |
'Observation points' |
'inspections' |
'Locations';
